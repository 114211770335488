import React from 'react'
import './FooterComponent.css'
import logoFooter from '../../assets/images/logo-mobile-footer.svg'
import iconFacebook from '../../assets/icons/icon-facebook-mobile.svg'
import iconInstagram from '../../assets/icons/icon-instagram-mobile.svg'
import iconTelegram from '../../assets/icons/icon-telegram-mobile.svg'
import iconTwitter from '../../assets/icons/icon-twitter-mobile.svg'

const FooterComponent = () => {
    return (
        <div className='footer flex-center' style={{justifyContent: 'space-around', width: '100%', marginTop: '2rem', position: 'relative'}}>
            <img src={logoFooter} alt='logo footer'/>
            <div style={{width: '45%', justifyContent: 'center', alignItems: 'center'}}>
                <div className='flex' style={{justifyContent: 'space-around'}}>
                <a href='https://app.laverdy.io/CGV'><p style={{cursor: 'pointer'}}>Mentions légales</p></a>
                    <a href='https://app.laverdy.io/signup' ><p style={{cursor: 'pointer'}}>Rejoindre le club</p></a>
                    <a href='https://app.laverdy.io/signin'><p style={{cursor: 'pointer'}}>Acheter Ethereum</p></a>
                    <a href="mailto:contact@laverdy.io"><p style={{cursor: 'pointer'}}>Contactez-nous</p></a>
                </div>
                <p>© 2021 LAVERDY CAPITAL, 10 Rue de penthièvre, 75008 Paris</p>
            </div>
            <div>
            <a href='https://www.twitter.com/laverdy_io'><img className='icon-footer' src={iconTwitter} alt='icon twiiter'/></a>
            <a href='https://www.instagram.com/laverdy.io'><img className='icon-footer' src={iconInstagram} alt='icon insta'/></a>
            <a href='https://www.facebook.com/laverdy.io'><img className='icon-footer' src={iconFacebook} alt='icon facebook'/></a>
            <a href='https://t.me/laverdy'><img className='icon-footer' src={iconTelegram} alt='icon telegram'/></a>
            </div>
        </div>
    )
}


export default FooterComponent