import React from 'react'
import './NavbarMobileComponent.css'
import imgLogo from '../../assets/images/logo-mobile.svg'
import navIcon from '../../assets/icons/navicon1.svg'
import iconClose from '../../assets/icons/close.svg'

const NavbarMobileComponent = ({ open, onOpen }) => {
    return (
        <>
            {
                open ?
                    <div className="nav-mobile">
                        <div className="nav-mobile-container">
                            <div className='container-img-nav'>
                                <img alt='logo mobile svg' src={imgLogo} />
                                <img onClick={() => onOpen()} alt='nav icon' src={iconClose} />
                            </div>
                            <div className='container-img-nav'>
                                <a href='https://app.laverdy.io/signin'><button className="btn-connection">Se connecter</button></a>
                                <a href='https://app.laverdy.io/signup'><button className="btn-connection">Rejoindre le club</button></a>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <a href='mailto:contact@laverdy.io'><button className="btn-contact">Nous contacter</button></a>
                            </div>
                        </div>
                    </div> :
                    <div className='container-nav-close'>
                        <img alt='logo mobile svg' src={imgLogo} />
                        <img onClick={() => onOpen()} alt='nav icon' src={navIcon} />
                    </div>
            }
        </>
    )
}

export default NavbarMobileComponent