import React from 'react'
import './NavbarComponent.css'
import logoNav from '../../assets/icons/logo-nav.svg'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const NavbarComponent = () => {
    return (
        <div className="navbar">
            <img src={logoNav} alt='logo navbar' />
            <div className='flex-center' style={{ justifyContent: 'space-around', width: '45%' }}>
                <a href={`/#section1`}><span>Notre solution</span></a>
                <a href={`/#section2`}><span>Conseils</span></a>
                <a href={`/#section3`}><span>Plan d'epargne</span></a>
                <a href="mailto:contact@laverdy.io"><span>Contact</span></a>
                <a href='https://app.laverdy.io/signup'><ButtonComponent text={'Rejoindre'} /></a>
            </div>
        </div>
    )
}

export default NavbarComponent