import React, { useEffect } from 'react'
import ButtonComponent from '../../components/ButtonComponent/ButtonComponent'
import NavbarComponent from '../../components/NavbarComponent/NavbarComponent'
import imgPlateforme from '../../assets/images/Template.png'
import iconNews from '../../assets/icons/icon-logo-news.svg'
import imgPig from '../../assets/icons/pig.svg'
import imgBuying from '../../assets/images/buying.svg'
import test from '../../assets/images/test.svg'
import imgInvest from '../../assets/images/invest.svg'
import './HomePage.css'
import HomeMobilePage from '../HomeMobilePage/HomeMobilePage'
import { useForm } from 'react-hook-form'
import Parse from 'parse';

import dollars from '../../assets/icons/dollars.svg'
import chart from '../../assets/icons/chart.svg'
import lock from '../../assets/icons/lock.svg'
import business from '../../assets/icons/business.svg'
import letter from '../../assets/icons/letter.svg'
import phone from '../../assets/icons/phone.svg'
import bubbles from '../../assets/images/bubbles.svg'
import rubiks from '../../assets/images/rubiks.svg'
import backNewsletter from '../../assets/images/background-newletter.svg'
import FooterComponent from '../../components/FooterComponent/FooterComponent'

function useWindowSize() {

    const [windowSize, setWindowSize] = React.useState(0);

    useEffect(() => {
        const isClient = typeof window === 'object';
        function getSize() {
            return {
                width: isClient ? window.innerWidth : undefined,
                height: isClient ? window.innerHeight : undefined,
            };
        }
        setWindowSize(getSize)
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}




const HomePage = () => {
    const size = useWindowSize();

    const { register, handleSubmit } = useForm();
    const [disabled, setDisabled] = React.useState(false);

    function addMailAddress(value) {
        const Email = Parse.Object.extend("Newsletter");
        const email = new Email()

        console.log('value', value)
        email.set("email", value.email)
        email.save().then((email) => {
            setDisabled(true)
        }, (error) => {
            alert('Failed to create new object, with error code: ' + error.message);
        })
    }

    const onSubmit = (d) => {
        addMailAddress(d)
    }

    return (
        <>
            { size.width < 1000 ? <HomeMobilePage /> :
                <section className="homepage sizing-height">
                    <NavbarComponent />
                    {/* first page  */}
                    <div className="firstpage flex-center">
                        <div style={{ maxWidth: '700px' }}>
                            <h1>Découvrez la 1ère plateforme Française d’investissement privé en crypto-monnaie.</h1>
                            <p>Plus qu’une plateforme, rejoignez un club et faites vous accompagner dans l’acquisition de vos crypto-actifs.</p>
                            <div className='flex align-center' style={{ marginTop: '2rem' }}>
                            <a href='https://app.laverdy.io/signup'><ButtonComponent text={'Rejoindre'} /></a>
                            <a href='https://app.laverdy.io/signin'><span>Déjà inscrit</span></a>
                            </div>
                        </div>
                        <div style={{ maxWidth: '700px' }}>
                            {/* <img src={imgPlateforme} alt='laverdy plateforme' /> */}
                            <img style={{ marginLeft: '-23rem', width: '1160px' }} src={imgPlateforme} alt='laverdy plateforme' />
                        </div>
                    </div>
                    {/* first page  */}

                    {/* solutionpage */}
                    <div className="solution-page flex-center sizing-height" id={'section1'}>
                        <div className='container-absolute-background' style={{ top: '3rem' }}>
                            <img src={rubiks} alt='rubiks' />
                        </div>
                        <div className="flex-center sizing-page" style={{ position: 'relative', zIndex: '999' }}>
                            <div style={{ width: '50%' }}>
                                <h1>Notre solution</h1>
                                <p className='solution-page-sub'>Nous démystifions l’univers de la cryptomonnaie. Nos tarifs sont clairs et nos engagements transparents.</p>
                                <p className='solution-page-sub-2'>Notre interface de gestion est simple, vous controlez en un coup d’oeil votre portefeuille et l’évolution de vos placements.</p>
                                <a href='https://app.laverdy.io/signup'><ButtonComponent  text={'Rejoindre'} /></a>
                            </div>
                            <div style={{ width: '50%' }} className='flex-center column'>
                                <div className="solution-card flex-center column">
                                    {/* <span>🤝</span> */}
                                    <img src={business} alt="business" />
                                    <h2>Rejoignez le Club</h2>
                                    <p>Des formules adaptées à tous les profils. Achat et vente de cryptomonnaies en toute simplicité</p>
                                </div>
                                <div className="solution-card flex-center column" style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                                    {/* <span>🔒</span> */}
                                    <img src={lock} alt="lock" />
                                    <h2>Sécurisé</h2>
                                    <p>Tous nos transferts de fonds sont sécurisés ainsi que toutes vos cryptomonnaies.</p>
                                </div>
                                <div className="solution-card flex-center column">
                                    {/* <span>💵</span> */}
                                    <img src={dollars} alt="dollars" />
                                    <h2>Frais transparent</h2>
                                    <p>L’ensemble des coûts sont détaillés en fonction de la formule choisie.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* solutionpage */}

                    {/* advice page */}
                    <div className="advice-page flex-center sizing-height" id={'section2'}>
                        <div className="flex-center sizing-page">
                            <div style={{ width: '55%' }} className='flex-center'>
                                <img src={test} alt='advice businessman' />
                            </div>
                            <div style={{ width: '45%', paddingRight: '9rem', position: 'relative', zIndex: "1000" }}>
                                <div className="container-absolute-background">
                                    <img src={bubbles} alt='bubbles' />
                                </div>
                                <div style={{ position: 'relative', zIndex: "1000" }}>
                                    <h1>Des conseillers Français pour vous accompagner!</h1>
                                    <p className="advice-page__sub-title">Ne vous préoccupez plus des étapes laborieuses et techniques. Optimisez votre temps, achetez, vendez et contrôlez vos actifs facilement. Nous nous occupons de tout.</p>
                                    <p className="advice-page__text">Vous pourrez placer votre argent sur des projets qui ont déjà fait leurs preuves.
                                    Chaque cryptomonnaie ajoutée à notre système remplit un certain nombre de critères pour<strong>limiter vos risques</strong> et <strong>optimiser vos gains.</strong></p>
                                    <p className='advice-item'><img src={chart} alt="chart" />Un suivi visuel grâce à notre dashboard</p>
                                    <p className='advice-item'><img src={letter} alt="letter" />Un suivi par mail</p>
                                    <p className='advice-item'><img src={phone} alt="phone" />Un suivi par téléphone</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* advice page */}

                    {/* invest page */}
                    <div className="invest-page flex-center sizing-height" id={'section3'}>
                        <div className="flex-center sizing-page">
                            <div style={{ width: '50%' }} className='flex-center column'>
                                <img alt='pig icons' src={imgPig} />
                                <h1>Une épargne digitale efficace. Nous proposons des placements sur des cryptomonnaies prometteuses, adaptées à votre budget.</h1>
                                <p className="advice-page__text">Commencez à épargner dès aujourd’hui, nous pouvons ensuite mettre en place ensemble un virement récurrent pour augmenter petit à petit votre <strong>crypto-capital.</strong></p>
                                <div style={{ width: '100%' }}>
                                    <p className="advice-page__text"><strong>Investissez sur une technologie avant-gardiste!<strong /></strong></p>
                                    <a href='https://app.laverdy.io/signup'><ButtonComponent text={'Rejoindre'} black={true} /></a>
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <img src={imgInvest} alt="invest page" />
                            </div>
                        </div>
                    </div>
                    {/* invest page */}

                    {/* buying-page */}
                    <div className='buying-page flex align-center column'>
                        <div style={{ width: '45%' }}>
                            <h1>Achetez différentes cryptomonnaies en toute sécurité, conservez-les sur notre coffre sécurisé.</h1>
                            <p className="advice-page__text" style={{ textAlign: 'center' }}>Vous êtes propriétaire des cryptomonnaies que vous achetez, nous assurons leurs stockage et pouvons également les transférer sur votre Wallet à votre guise.</p>
                        </div>
                        <img src={imgBuying} alt='buying crypto' />
                    </div>
                    {/* buying-page */}

                    {/* newsletter */}
                    <div className="newsletter flex column align-center" style={{position: 'relative', overflow: 'hidden'}}>
                        <img src={backNewsletter} alt='background newsletter' style={{ position: 'absolute', top: '-120px', left: '20px' }} />
                        <div className='flex justify-center'>
                            <img src={iconNews} alt='icon newsletter' />
                            <p className='title'>Restez informé, souscrivez à notre newsletter</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className='newsletter__container-input flex justify-center' style={{ padding: '10px', position: 'relative', zIndex: '1000' }}>
                            <input required {...register("email")} placeholder='Entrer votre adresse email' type='email' />
                            <button disabled={disabled} type='submit' className='custom-btn'>Souscrire</button>
                        </form>
                        {disabled && <p className="validation-news">Incription validé</p>}
                        <FooterComponent />
                    </div>
                    {/* newsletter */}
                </section>
            }
        </>
    )
}

export default HomePage