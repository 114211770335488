import React from 'react'
import ButtonComponent from '../../components/ButtonComponent/ButtonComponent'
// import imgPlateforme from '../../assets/images/plateforme-mobile.svg'
import imgPlateforme from '../../assets/images/Template.png'
import imgPig from '../../assets/images/pig-mobile.svg'
import imgInvest from '../../assets/images/invest-mobile.svg'
import imgBuying from '../../assets/images/buying-mobile.svg'
import imgLogoFooter from '../../assets/images/logo-mobile-footer.svg'
import iconFacebook from '../../assets/icons/icon-facebook-mobile.svg'
import iconInstagram from '../../assets/icons/icon-instagram-mobile.svg'
import iconTelegram from '../../assets/icons/icon-telegram-mobile.svg'
import iconTwitter from '../../assets/icons/icon-twitter-mobile.svg'
import './HomeMobilePage.css'
import iconNews from '../../assets/icons/icon-logo-news.svg'
import { useForm } from 'react-hook-form'
import Parse from 'parse';
import NavbarMobileComponent from '../../components/NavbarMobileComponent/NavbarMobileComponent'

const HomeMobilePage = () => {
    const { register, handleSubmit } = useForm();
    const [disabled, setDisabled] = React.useState(false);
    const [openNav, setOpenNav] = React.useState(false);

    function addMailAddress(value) {
        const Email = Parse.Object.extend("Newsletter");
        const email = new Email()

        console.log('value', value)
        email.set("email", value.email)
        email.save().then((email) => {
            setDisabled(true)
        }, (error) => {
            alert('Failed to create new object, with error code: ' + error.message);
        })
    }

    const onSubmit = (d) => {
        addMailAddress(d)
    }

    const onOpenNav = () => {
        setOpenNav(!openNav)
    }


    return (
        <>
            <section>
                <NavbarMobileComponent open={openNav} onOpen={() => onOpenNav()}></NavbarMobileComponent>
            </section>
            <section className="homemobile">
                {/* first page  */}
                <div className="firstpage-mobile flex align-center column">
                    <h1>Découvrez la 1ère plateforme Française d’investissement privé en crypto-monnaie.</h1>
                    <p>Plus qu’une plateforme, rejoignez un club et faites vous accompagner dans l’acquisition de vos crypto-actifs.</p>
                    <img style={{width: '100%'}} alt='plateforme pict' src={imgPlateforme} />
                    <a href='https://app.laverdy.io/signup'><ButtonComponent  text={'Rejoindre'} /></a>
                </div>
                {/* first page  */}

                {/* solutionpage */}
                <div className="solution-mobile flex-center column">
                    <h1>Notre solution</h1>
                    <p className='solution-page-sub-mobile'>Nous démystifions l’univers de la cryptomonnaie. Nos tarifs sont clairs et nos engagements transparents.</p>
                    <p className='solution-page-sub-2-mobile'>Notre interface de gestion est simple, vous controlez en un coup d’oeil votre portefeuille et l’évolution de vos placements.</p>
                    <div className="solution-card-mobile flex-center" style={{ justifyContent: 'space-around' }}>
                        <span>🤝</span>
                        <div style={{ width: '70%' }}>
                            <h2>Rejoignez le Club</h2>
                            <p>Des formules adaptées à tous les profils. Achat et vente de cryptomonnaies en toute simplicité</p>
                        </div>
                    </div>
                    <div className="solution-card-mobile flex-center" style={{ marginTop: '1.5rem', marginBottom: '1.5rem', justifyContent: 'space-around' }}>
                        <span>🔒</span>
                        <div style={{ width: '70%' }}>
                            <h2>Sécurisé</h2>
                            <p>Tous nos transferts de fonds sont sécurisés ainsi que toutes vos cryptomonnaies.</p>
                        </div>
                    </div>
                    <div className="solution-card-mobile flex-center" style={{ justifyContent: 'space-around', marginBottom: '3rem' }}>
                        <span>💵</span>
                        <div style={{ width: '70%' }}>
                            <h2>Frais transparent</h2>
                            <p>L’ensemble des coûts sont détaillés en fonction de la formule choisie.</p>
                        </div>
                    </div>
                    <a href='https://app.laverdy.io/signup'><ButtonComponent text={'Rejoindre'} /></a>
                </div>
                {/* solutionpage */}

                {/* advice page */}
                <div className="advice-mobile flex-center column">
                    <h1>Des conseillers Français pour vous accompagner!</h1>
                    <p className="advice-mobile__sub-title">Ne vous préoccupez plus des étapes laborieuses et techniques. Optimisez votre temps, achetez, vendez et contrôlez vos actifs facilement. Nous nous occupons de tout.</p>
                    <p className="advice-mobile__text">Vous pourrez placer votre argent sur des projets qui ont déjà fait leurs preuves. 
                    Chaque cryptomonnaie ajoutée à notre système remplit un certain nombre de critères pour <strong>limiter vos risques</strong> et <strong>optimiser vos gains.</strong></p>
                    <div>
                        <p className='advice-item-mobile'><span>📈</span>Un suivi visuel grâce à notre dashboard</p>
                        <p className='advice-item-mobile'><span>✉️</span>Un suivi par mail</p>
                        <p className='advice-item-mobile'><span>📱</span>Un suivi par téléphone</p>
                    </div>
                </div>
                {/* advice page */}

                {/* invest page */}
                <div className="invest-page-mobile flex-center column">
                    <img alt='pig icons' className='pig-mobile' src={imgPig} />
                    <h1>Une épargne digitale efficace.</h1>
                    <p className="advice-page__text-mobile">Commencez à épargner dès aujourd’hui, nous pouvons ensuite mettre en place ensemble un virement récurrent pour augmenter petit à petit votre <strong>crypto-capital.</strong></p>
                    <img src={imgInvest} alt="invest page" />
                    <a href='https://app.laverdy.io/signup'><ButtonComponent text={'Rejoindre'} black={true} /></a>
                </div>
                {/* invest page */}

                {/* buying-page */}
                <div className='buying-mobile'>
                    <div className='container'>
                        <h1>Achetez différentes cryptomonnaies en toute sécurité, conservez-les sur notre coffre sécurisé.</h1>
                        <p className="advice-page__text" style={{ textAlign: 'center' }}>Vous êtes propriétaire des cryptomonnaies que vous achetez, nous assurons leurs stockage et pouvons également les transférer sur votre Wallet à votre guise.</p>
                    </div>
                    <img style={{ width: '100%' }} src={imgBuying} alt='buying crypto' />
                </div>
                {/* buying-page */}

                {/* newsletter */}
                <form onSubmit={handleSubmit(onSubmit)} className="newsletter-mobile flex-center column">
                    <div className='flex justify-center column align-center'>
                        <img src={iconNews} alt='icon newsletter' />
                        <p className='title'>Restez informé, souscrivez à notre newsletter</p>
                    </div>
                    <div className='newsletter__container-input-mobile justify-center align-center flex column' style={{ padding: '10px' }}>
                        <input required {...register("email")} placeholder='Entrer votre adresse email' type='email' />
                        {disabled && <p className="validation-news">Incription validé</p>}
                        <div style={{ marginTop: '1rem' }}>
                            <button disabled={disabled} type='submit' className='custom-btn'>Souscrire</button>
                        </div>
                    </div>
                </form>
                {/* newsletter */}
                <div className='footer-mobile'>
                    <div className='flex' style={{ justifyContent: 'space-between' }}>
                        <img src={imgLogoFooter} alt="logo footer" />
                        <div style={{ width: '30%', justifyContent: 'space-between' }} className='flex'>
                            <a href='https://www.twitter.com/laverdy_io'><img alt='iconTwitter' src={iconTwitter} /></a>
                            <a href='https://www.instagram.com/laverdy.io'><img alt='iconInstagram' src={iconInstagram} /></a>
                            <a href='https://www.facebook.com/laverdy.io'><img alt='iconFacebook' src={iconFacebook} /></a>
                            <a href='https://t.me/laverdy'><img alt='iconTelegram' src={iconTelegram} /></a>
                        </div>
                    </div>
                    <div className='flex' style={{ justifyContent: 'space-around', marginTop: '1rem' }}>
                        <a href='https://app.laverdy.io/CGV'><span>Mentions légales</span></a>
                        <a href='https://app.laverdy.io/signup'><span>Rejoindre le club</span></a>
                        <a href='https://app.laverdy.io/signin'><span>Acheter Ethereum</span></a>
                    </div>
                    <p>© 2021 LAVERDY CAPITAL, 10 Rue de penthièvre, 75008 Paris</p>
                </div>
                {/* <FooterComponent /> */}
            </section>
        </>
    )
}

export default HomeMobilePage